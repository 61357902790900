import React, { ReactNodeArray, useMemo } from "react";
import { useIntl } from "react-intl";
import { AnchorButton, Card } from "@blueprintjs/core";
import * as _ from "lodash";
import DownloadResourceButton from "./DownloadResourceButton";
import ThirdGuidelineStepContent from "./ThirdGuidelineStepContent";
import GenericCollapse from "../../../common/GenericCollapse";
import {
  ACTIVITY_LINK_CLASSNAME,
  useAttachActivities,
} from "../../../../helpers/hooks/useAttachActivities";
import { Activity, ActivityTypeEnum } from "../../../../types";
import { showNotesDialog } from "../../../../store/notes/actions";
import { useDispatch } from "react-redux";
import { showActivityVideoDialog } from "../../../../store/activities/actions";

type OwnProps = {};

type Props = OwnProps;

const GUIDELINES_URL =
  "https://platform.njschoolclimate.org/files/activity/MTE=/download/pdf/NJ+SCI+Survey+Administration+Guidelines+For+Data+Coordinators+PDF.pdf";

const NUMBER_OF_STEPS = 6;

type AdministrationGuidelineStepType = {
  title: ReactNodeArray | string;
  description?: string | ReactNodeArray;
  descriptionContent?: JSX.Element;
};

const AdministrationGuidelinesTab: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { onActivityClick } = useAttachActivities({
    onClick: (activity: Activity) => {
      switch (activity.activity_type) {
        case ActivityTypeEnum.SCI_WRITING:
          dispatch(
            showNotesDialog({
              selectedActivity: activity,
            })
          );
          break;
        case ActivityTypeEnum.KALTURA_VIDEO:
          dispatch(
            showActivityVideoDialog({
              activity: activity,
              viewable_field: "embed_code",
            })
          );
          break;

        default:
          break;
      }
    },
  });

  const steps = useMemo<AdministrationGuidelineStepType[]>(
    () =>
      _.range(NUMBER_OF_STEPS).map((step) =>
        step === 3
          ? {
              title: intl.formatMessage(
                { id: `app.administration-guidelines.steps.${step}.title` },
                { stepNumber: step, i: (chunks) => <i>{chunks}</i> }
              ),
              descriptionContent: <ThirdGuidelineStepContent />,
            }
          : {
              title: intl.formatMessage(
                { id: `app.administration-guidelines.steps.${step}.title` },
                { stepNumber: step }
              ),
              description: intl.formatMessage(
                {
                  id: `app.administration-guidelines.steps.${step}.description`,
                },
                {
                  i: (chunks) => <i>{chunks}</i>,
                  b: (chunks) => <b>{chunks}</b>,
                  a: (chunks) => (
                    <a
                      className="underline text-blue-500 cursor-pointer hover:text-blue-800 italic"
                      target="_blank"
                      href=" https://www.nj.gov/education/safety/sandp/climate/docs/Guidance.pdf"
                    >
                      {chunks}
                    </a>
                  ),
                  asurvey: (chunks) => (
                    <a
                      className="underline text-blue-500 cursor-pointer hover:text-blue-800 italic"
                      target="_blank"
                      href="https://platform.njschoolclimate.org/files/activity/NDA=/download/pdf/Pilot+NJ+SCI+Survey+All+Students.pdf"
                    >
                      {chunks}
                    </a>
                  ),
                  stafflink: (chunks) => (
                    <a
                      className="underline text-blue-500 cursor-pointer hover:text-blue-800 italic"
                      target="_blank"
                      href="https://platform.njschoolclimate.org/files/activity/MTI=/download/pdf/NJ+SCI+Survey+Administration+Guidelines+for+Staff+Administering+to+Students.pdf"
                    >
                      {chunks}
                    </a>
                  ),
                  mail_to: (chunks) => (
                    <a
                      className="underline text-blue-500 cursor-pointer hover:text-blue-800"
                      target="_blank"
                      href="mailto:njscisupport@rutgers.edu"
                    >
                      {chunks}
                    </a>
                  ),
                  u: (chunks) => <u>{chunks}</u>,
                  br: <br />,
                  activity: (chunks) => (
                    <a
                      className={ACTIVITY_LINK_CLASSNAME}
                      onClick={onActivityClick()}
                    >
                      <b>{chunks}</b>
                    </a>
                  ),
                }
              ),
            }
      ),
    [onActivityClick]
  );

  return (
    <>
     <div className="teamContent">
      <Card className="p-0" elevation={2}>
        {steps.map((s, i) => (
          <div key={i} className="mb-2">
            <GenericCollapse title={s.title}>
              {s.descriptionContent ?? s.description}
            </GenericCollapse>
          </div>
        ))}
        <div className="flex justify-end flex-wrap mt-4">
          <div className="mr-2">
            <AnchorButton
              className={ACTIVITY_LINK_CLASSNAME}
              large
              intent="primary"
              text="View Companion Video"
              title="View Companion Video"
              onClick={onActivityClick(
                "Overview of NJ SCI Survey Administration"
              )}
              outlined
            />
          </div>
          <div>
            {/* This will eventually be replaced with a better way to link to this file */}
            <DownloadResourceButton
              text="Download Guidelines"
              href={GUIDELINES_URL}
            />
          </div>
        </div>
      </Card>
      </div>
    </>
  );
};

export default AdministrationGuidelinesTab;
