import React from "react";

type OwnProps = {};

type Props = OwnProps;

const ThirdGuidelineStepContent: React.FC<Props> = (props: Props) => {
  return (
    <div className="pl-4">
      <p>
        A. <u>Create a schedule</u>
      </p>
      <ul className="list-disc pl-12">
        <li key={0}>
          In a multi-school district, consult with district leaders first to
          find out whether the district will coordinate administration of the
          survey across all schools or allow individual schools to select their
          own timeframes. It is recommended that districts coordinate survey
          administration to allow for more meaningful data comparisons that are
          less impacted by timing-related factors or major events in the
          community. Aligning survey administration across schools in the same
          district also allows for more efficient communication with members of
          the school community and sends a unified message about the importance
          of the survey.
        </li>
        <li key={1}>
          In the “Data Collection and Reports” workspace on the NJ SCI Platform,
          under the “Survey Deployment” tab, click on the option to deploy the
          NJ SCI Survey and designate a “Start Date” and “End Date” for each
          respondent group (e.g., Students Grades 3-5; Students Grades 6-12)
          based on scheduling considerations below. You may assign different
          start and end dates for different groups. Links for all relevant
          groups are created within a single survey deployment. Keeping groups within the same deployment will ensure that survey
          results can be easily compared as part of the same data report. Once a
          deployment is created, you will be able to revise survey start and end
          dates as needed.
        </li>
        <li key={2}>
          If feasible, staff should take the survey within 7-10 days of release
          of the survey link. Consider giving staff time to take the survey
          during a faculty meeting or as part of an extended or additional
          preparation or professional development period. As a reminder,
          participation in the survey is voluntary. No respondent should feel
          pressured to participate if they do not want to.
        </li>
        <li key={3}>
          Survey parents/caregivers within 14 days of release of the survey
          link. Additional time can be given to promote participation, as
          needed. Consider providing opportunities for parents/caregivers to
          take the survey when they are in the building for other purposes.
        </li>
        <li key={4}>
          Plan to administer surveys to all students within 5 school days, if
          feasible. If your school has students in grades 3-5 as well as grades
          6-12, schedule administration of both versions of the survey to
          students within the same time frame. Student surveys are only
          accessible on weekdays between the hours of 6 a.m. and 6 p.m.; anyone
          trying to access the surveys outside of those hours will be redirected
          to a page stating that they should return to the survey during typical
          school hours. The student surveys will time out after 24 hours of
          being open for security purposes.
        </li>
        <li key={5}>
        The NJ SCI Survey takes about 15-20 minutes to complete for students in grades 3-5 and 
        about 10-15 minutes for students in grades 6-12.  For students, we recommend setting 
        aside a single-block class period (about 40-45 minutes) to ensure that staff have enough time 
        to introduce the survey, give instructions on how to access the link, and answer questions.
         On average, the Staff survey takes about 10-15 minutes to complete and
         the Parent/Caregiver survey takes about 7-10 minutes. 
        </li>
        <li key={6}>
          Avoid surveying too early in the school year or immediately following
          long breaks to allow routines to solidify; avoid time around holidays,
          standardized testing periods, or periods immediately following
          significant events.
        </li>
        <li key={7}>
          Survey links will close at 11:59 pm on specified “End Dates.” The
          platform will send an automated reminder email to the Data
          Coordinator(s), School Principal/Team Champion, and Team Chair(s) a
          few days prior to the closing of a survey link with a prompt to revise
          “End Dates” if additional time is needed.
        </li>
      </ul>
      <p>
        B. <u>Coordinate space and technology</u>
      </p>
      <ul className="list-disc pl-12">
        <li key={1}>
          <b>
          Students should complete the survey while in the school building under the supervision of staff
          </b>{" "}
          who are prepared to administer the survey, preserve confidentiality, and refer students to designated adults (“floaters”) in order to safely and privately ask questions.
        </li>
        <li key={2}>
          Identify survey locations (i.e., computer lab, classrooms) and times
          to ensure minimal distractions. Determine which devices students will
          use to take the survey while in the building.
        </li>
        <li key={3}>
          The NJ SCI Survey can be completed on computers/laptops, tablets, and
          mobile devices. Test Wi-Fi bandwidth to accommodate the number of
          students taking the survey. Surveys should only be completed
          electronically.
        </li>
      </ul>
      <p>
        C. <u>Plan for additional accommodations for students, as necessary</u>
      </p>
      <ul className="list-disc pl-12">
        <li>
          <b>Consider special accommodations for students as needed</b>, such as
          make-up days, quiet spaces/rooms, and extra time to complete the
          survey. While planning for accommodations and extended time, please
          keep in mind that after 24 hours of being open, the student survey
          link will time out for security purposes. Any accommodations provided
          should be paired with efforts to protect the confidentiality of
          students’ responses.
        </li>
        <li key={2}>
          <b>Examples of potential accommodations</b> and modifications for
          students with disabilities may include:
        </li>
        <ul className="list-disc pl-12" style={{ listStyleType: "square" }}>
          <li key={0}>Reading the survey aloud</li>
          <li key={1}>
            Allowing for frequent breaks (the survey link will not time out
            within 24 hours of being open if it is left open on the webpage and
            the device being used is not shut down)
          </li>
          <li key={2}>
            Providing extra time to participate in the survey process (but no
            more than 24 hours beyond when the survey is first opened)
          </li>
          <li key={3}>
            Providing alternative settings for survey administration
          </li>
          <li key={4}>Assisting in actual survey participation/keyboard use</li>
          <li key={5}>
            Consulting with special services staff for assistance, if needed
          </li>
          <li key={6}>
            Considering other differentiation supports for students. (e.g.,
            Text-To-Speech Reader)
          </li>
        </ul>
      </ul>
    </div>
  );
};

export default ThirdGuidelineStepContent;
