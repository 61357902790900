import { Button, Icon, Tag, } from "@blueprintjs/core";
import React, { FunctionComponent, useEffect, useMemo, useState, useCallback, useRef } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CellProps } from "react-table";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { AppToaster } from "../../../../helpers/toaster";
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import {
  deleteTeamMemberFromPlan,
  showUnderstandingRolesDialog,
  showUpdateTeamMemberDialog,
  updateTeamMember,
} from "../../../../store/team-members/actions";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../../store/UIState/actions";
import { redispatchInvite, showAddUserToPlanDialog, showInviteUsersDialog } from "../../../../store/users/actions";
import { getTeamMembersByPlan } from "../../../../store/team-members/actions";
import {
  InviteStatus,
  TeamMember,
  TeamRole,
  UserModel,
  UserRole,
  UserNotesType,
  DistrictSetupTaskKey,
} from "../../../../types";
import EntityTable from "../../../common/EntityTable";
import TeamMemberRoleCell from "./TeamMemberRoleCell";
import TeamMemberSchoolRoleCell from "./TeamMemberSchoolRoleCell";
import useUserRole from "../../../../helpers/hooks/useUserRole";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import TeamEntityTable from "../tabs/team-home-tab/TeamEntityTable";
import { generatePath, useHistory, useParams } from "react-router";
import { AuthRoutes } from "../../../../App";
import { markStepCompleted, selectDistrictSetupTaskKey } from "../../../../store/tours/actions";
import Dialog from '@mui/material/Dialog';

type OwnProps = {
  members: TeamMember[];
  disableDccSelector?: boolean;
  readonly?: boolean;
};

type Props = OwnProps;

const TeamMembersTable: FunctionComponent<Props> = (props) => {
  const { members: initialMembers, readonly, disableDccSelector } = props;
  const pageInd = useRef(0);

  const currentUser = useSelector((s) => s.auth.authenticatedUser);
  const updateTeamMemberLoading = useSelector(
    (s) => s.teamMembers.loading.updateTeamMember
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    isDCC,
    role,
    teamRole,
    isSuperintendent,
    isPrincipal,
    isAdmin,
    isTeamChampion,
    hasDistrictPlanAccess,
  } = useUserRole();
  const activePlan = useSelector((s) => s.plans.activePlan);
  const teamRoles = useSelector((s) => s.auth.authenticatedUser?.team_roles);
  const isDistrictActivePlan = useSelector(isDistrictPlan);
  const [domainDeleteModalOpen, setDomainDeleteModalOpen] = useState <boolean>(false);
  const [inviteMemberModalOpen, setInviteMemberModalOpen] = useState <boolean>(false);
  const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null); 
  const [removeDCC, setRemoveDCC] = useState <boolean>(false);
  
  const handleDeleteOpenModal = (member: TeamMember) => {
     setSelectedMember(member);
     setDomainDeleteModalOpen(true);
     }; 
  
  const handleInviteMemberOpenModal = (member: TeamMember) => {
     setSelectedMember(member);
     setInviteMemberModalOpen(true);
     };    

  const handleCloseModal = () => { 
    setDomainDeleteModalOpen(false); 
    setInviteMemberModalOpen(false)
    setSelectedMember(null);
   };
 
  const { tabId } = useParams<{ tabId?: string }>();

  const selectedTabId: UserNotesType = useMemo(() => {
    return tabId &&
      [
        UserNotesType.SCLT_TEAM_HOME,
        UserNotesType.SCLT_SCI_CALENDAR,
        UserNotesType.SCLT_RESOURCES,
        UserNotesType.DCLT_TEAM_HOME,
        UserNotesType.DCLT_SCI_CALENDAR,
        UserNotesType.DCLT_RESOURCES,
      ].includes(tabId as UserNotesType)
      ? (tabId as UserNotesType)
      : isDistrictActivePlan
      ? UserNotesType.DCLT_TEAM_HOME
      : UserNotesType.SCLT_TEAM_HOME;
  }, [tabId, isDistrictActivePlan]);


  const currentUserEditingAccessRoles = useMemo(() => {
    if (role) {
      const allRoles = Object.values(UserRole);
      return allRoles.slice(
        allRoles.indexOf(isDCC ? UserRole.Superintendent : role),
        allRoles.length
      );
    }
    return [];
  }, [isDCC, role]);

  const isTeamRoleHigherOrEqualTeamChair = useMemo(() => {
    return isTeamChampion || teamRole === TeamRole.TeamChair;
  }, [isTeamChampion, teamRole]);

  const intl = useIntl();


  const redispatchLoading = useSelector(
    (s) => s.users.loading.redispatchInvite
  );
  const redispatchError = useSelector((s) => s.users.errors.redispatchInvite);
  useLoading({
    loading: redispatchLoading,
    error: redispatchError,
    onSuccess: () => {
      AppToaster.show({
        message: intl.formatMessage({ id: "app.toaster.invite-redispatched" }),
        icon: "tick",
        intent: "success",
      });
      if (activePlan?.id)
        dispatch(
          getTeamMembersByPlan.request({
            planId: activePlan.id,
            include_district_user: false,
          })
        );
    },
  });

  const handleTeamMemberEditClick = (t: TeamMember) => () => {
    dispatch(
      showUpdateTeamMemberDialog({ planId: t.plan, user: t.user as UserModel })
    );
  };

  const inviteDateCalculator = (numOfDays: number, date: Date) => {
    const daysAgo = new Date(date.getTime());
    daysAgo.setDate(date.getDate() - numOfDays);
    return daysAgo;
  };

  const isDistrictRole = (role: UserRole) => {
    const districtUsers: UserRole[] = [UserRole.Superadmin, UserRole.NJ_SCI_Leadership, UserRole.NJ_SCI_TaProvider, UserRole.Superintendent, UserRole.DistrictStaff];
    return districtUsers.includes(role);
  }

  const showRemoveFromTeamButton = (member: TeamMember) => {
    // const currentUserRole =  currentUser ?currentUser.profile.role:null;
    // const allowedListOfSelf:UserRole[] = [UserRole.Superadmin,UserRole.NJ_SCI_Leadership];
    // allowedListOfSelf.includes(currentUserRole)
    const notAllowedListOfOtherMember: UserRole[] = [UserRole.Superadmin, UserRole.NJ_SCI_Leadership, UserRole.NJ_SCI_TaProvider, UserRole.Superintendent, UserRole.DistrictStaff];
    return (isDCC || isSuperintendent || isAdmin) && !notAllowedListOfOtherMember.includes(member?.user.profile?.role) && !member?.user?.profile?.is_dcc;
  }
  const onRemoveTeamMemberFromPlanConfirm = (member: TeamMember) => {
    const planFound = member.user.team_roles?.find((et: any) => et.plan === activePlan?.id);
    if (planFound?.plan && member.user) {
      dispatch(
        deleteTeamMemberFromPlan.request({
          planId: planFound?.plan,
          userId: member.user.id,
        })
      );
    }
  };
  
 
  const callbackFilter = useMemo(
    () => (t: TeamMember) => {
      const InviteNotAccepted =
        (t.invited_status === InviteStatus.Sent && t.invite_id) ||
        t.invited_status === InviteStatus.Expired;

      const isDeactivated = t.user.is_active === false && !InviteNotAccepted;
      const deactivateUserName = isDeactivated
        ? intl.formatMessage({ id: "app.titles.deactivated" }) + ' ' + t.user.first_name
        : t.user.first_name;

      return {
        ...t,
        invited_status: t.invited_status === InviteStatus.Failed || t.invited_status === null
          ? "N/A"
          : t.invited_status,
        user: {
          ...t.user,
          first_name: deactivateUserName,
        },
      };
    },
    [intl]
  );
  const filteredMembers = useMemo(() => initialMembers.map(callbackFilter), [initialMembers, callbackFilter]);
  const members = filteredMembers;





  const {
    selectedDistrictSetupTaskKey: dialogInfoKey,
    setupCompleted,
    districtSetupState: tours,
  } = useSelector((s) => s.tours);

  const inviteUserButtonTitle = useMemo(() => {
  
    return isDistrictActivePlan
      ? "app.team-plan.setup.team.invite-new-member-district"
      : "app.team-plan.setup.team.invite-new-member-school";
  }, [isDistrictActivePlan]);

  const showManageUsersSection = useMemo(() => {
    return (
      (selectedTabId === UserNotesType.SCLT_TEAM_HOME ||
        selectedTabId === UserNotesType.DCLT_TEAM_HOME) &&
      (hasDistrictPlanAccess ||
        (teamRole &&
          !isDistrictActivePlan &&
          (teamRole === TeamRole.TeamChampion ||
            teamRole === TeamRole.TeamChair)))
    );
  }, [selectedTabId, hasDistrictPlanAccess, teamRole, isDistrictActivePlan]);


  const handleShowInviteUsersDialog = () => {
    dispatch(
      showInviteUsersDialog({
        onSuccess: (members || []).some((m) => m.user?.profile?.is_dcc)
          ? undefined
          : () => {
              history.push(
                generatePath(AuthRoutes.YourPlan, {
                  workspace: "dashboard",
                } as any)
              );
              dispatch(
                markStepCompleted({
                  key: DistrictSetupTaskKey.DesignateDistrictClimateCoordinator,
                })
              );
              dispatch(
                selectDistrictSetupTaskKey(
                  DistrictSetupTaskKey.FamiliarizeWithSccp
                )
              );
            },
      })
    );
  };

  const handleShowAddUsersToPlanDialog = () => {
    dispatch(showAddUserToPlanDialog());
  };
  

  const DeleteMemberModal = useMemo(() => {
    return (
      <Dialog
        className="medium-popup"
        open={domainDeleteModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="cancel">
          <button onClick={handleCloseModal}>
            <Icon icon="cross" iconSize={18} />
          </button>
        </div>
        <div className="d-flex justify-center">
          {" "}
          <svg
            width="49"
            height="49"
            viewBox="0 0 49 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="24.1016"
              cy="24.2056"
              r="22.458"
              fill="white"
              stroke="black"
              stroke-width="3"
            />
            <path
              d="M22.9715 19.519H25.9955V36.151H22.9715V19.519Z"
              fill="black"
            />
            <circle cx="24.3193" cy="14.0448" r="2.28711" fill="black" />
          </svg>
        </div>

        <p>
          {intl.formatMessage(
            {
              id: "app.confirm-dialog.remove-team-member-from-plan",
            },
            {
              b: (chunks) => chunks,
              br: <br />,
            }
          )}
        </p>
        <div className="button-group flex">
          <button onClick={handleCloseModal} className="stroke-btn">
            {intl.formatMessage({
              id: "app.confirm-dialog.remove-team-member-from-plan.no-option",
            })}
          </button>
          <button
            onClick={() =>
              selectedMember &&
              onRemoveTeamMemberFromPlanConfirm(selectedMember)
            }
            className="nj-btn"
          >
            {intl.formatMessage({
              id: "app.confirm-dialog.remove-team-member-from-plan.yes-option",
            })}
          </button>
        </div>
      </Dialog>
    );
  }, [domainDeleteModalOpen, selectedMember]);

  const resendInviteMemberModal = useMemo(() => {
    if (selectedMember?.invite_id) {
      return (
        <Dialog
          className="medium-popup"
          open={inviteMemberModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="cancel">
            <button onClick={handleCloseModal}>
              <Icon icon="cross" iconSize={18} />
            </button>
          </div>
          <div className="d-flex justify-center mb-1">
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="24.1016"
                cy="24.2056"
                r="22.458"
                fill="white"
                stroke="black"
                stroke-width="3"
              />
              <path
                d="M22.9715 19.519H25.9955V36.151H22.9715V19.519Z"
                fill="black"
              />
              <circle cx="24.3193" cy="14.0448" r="2.28711" fill="black" />
            </svg>
          </div>

          <p>
            {intl.formatMessage(
              {
                id: "app.confirmation-dialogs.redispatch-invite",
              },
              {
                email: selectedMember?.user?.email,
                br: <br />,
              }
            )}
          </p>
          <div className="button-group flex">
            <button onClick={handleCloseModal} className="stroke-btn">
              {intl.formatMessage({ id: "app.titles.close" })}
            </button>
            <button
              onClick={() =>
                selectedMember &&
                dispatch(
                  redispatchInvite.request(selectedMember.invite_id as number)
                ) &&
                handleCloseModal()
              }
              className="nj-btn"
            >
              {intl.formatMessage({
                id: "app.confirmation-dialogs.resend-invite-confirm",
              })}
            </button>
          </div>
        </Dialog>
      );
    }
  }, [inviteMemberModalOpen, selectedMember]);

  const columns: any[] = useMemo(() => {
    const cols = [
      {
        id: "name",
        Header: intl.formatMessage({ id: "app.titles.name" }),
        disableSortBy: true,
        sortType: (a: any, b: any) => {
          return (
            b.original.user.first_name > a.original.user.first_name)
        },
        disableFilters: true,
        accessor: (t: TeamMember) => {
          const inviteDate = inviteDateCalculator(
            14,
            new Date(t?.invite_expires_date as string)
          );
          const inviteDateString =
            inviteDate.getMonth() +
            1 +
            "/" +
            inviteDate.getDate() +
            "/" +
            inviteDate.getFullYear().toString().substr(-2);
          const isInviteNotAccepted =
            (t.invited_status === InviteStatus.Sent && t.invite_id) ||
            t.invited_status === InviteStatus.Expired;

          return (
            <>
            {`${t.user.first_name} ${t.user.last_name}`}
            </> 
          );
        },
      },
      {
        id: "email",
        Header: intl.formatMessage({ id: "app.titles.email" }),
        sortType: (a: any, b: any) => {
          return (
            b.original.user.first_name > a.original.user.first_name)
        },
        disableFilters: true,
        disableSortBy: true,
        accessor: (t: TeamMember) => {
          const inviteDate = inviteDateCalculator(
            14,
            new Date(t?.invite_expires_date as string)
          );
          const inviteDateString =
            inviteDate.getMonth() +
            1 +
            "/" +
            inviteDate.getDate() +
            "/" +
            inviteDate.getFullYear().toString().substr(-2);
          const isInviteNotAccepted =
            (t.invited_status === InviteStatus.Sent && t.invite_id) ||
            t.invited_status === InviteStatus.Expired;

          return (
            <>
            <a href={`mailto:${t.user.email}`}>{t.user.email}</a>
            </> 
          );
        },
      },
      {
        id: "school-role",
        Header: intl.formatMessage({ id: "app.titles.district-school-role" }),
        disableSortBy: true,
        accessor: (t: TeamMember) => t.user.profile.custom_role_name,
        Cell: ({ row: { original } }: CellProps<TeamMember>) => (
          <TeamMemberSchoolRoleCell
            user={original.user}
            hasEditingAccess={
              currentUserEditingAccessRoles.includes(
                original.user.profile?.role
              ) && !readonly
            }
          />
        ),
      },


{
  id: "invite-status",
  Header: intl.formatMessage({
    id: "app.titles.invite.status",
  }),
  sortType: (a: any, b: any) => {
    return b.original.user.first_name > a.original.user.first_name;
  },
  disableFilters: true,
  disableSortBy: true,
  accessor: (t: TeamMember) => {
    const inviteDate = inviteDateCalculator(
      14,
      new Date(t?.invite_expires_date as string)
    );
    const inviteDateString =
      inviteDate.getMonth() +
      1 +
      "/" +
      inviteDate.getDate() +
      "/" +
      inviteDate.getFullYear().toString().substr(-2);

    // Determine the invite status
    let inviteStatus = t.invited_status;
    if (inviteStatus === InviteStatus.Failed || inviteStatus === null) {
      inviteStatus = InviteStatus.NA;
    }

    const isInviteNotAccepted =
      (inviteStatus === InviteStatus.Sent && t.invite_id) ||
      inviteStatus === InviteStatus.Expired ||
      inviteStatus === InviteStatus.NA;


    return (
      <div>
        <div className="flex items-center space-x-1">
          {isInviteNotAccepted ? (
            <span className="itemsent" style={{ textTransform: "capitalize" }}>
              {inviteStatus}
            </span>
          ) : (
            <span className="accepted">Accepted</span>
          )}
        </div>
      </div>
    );
  },
},
     
      {
        id: "team-role",
        hidden: isDistrictActivePlan,
        disableSortBy: true,
        Header: () => (
          <div className="flex items-center space-x-2">
            <div>{intl.formatMessage({ id: "app.titles.team-role" })}</div>
            <Icon
              className="cursor-pointer"
              icon="info-sign"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(showUnderstandingRolesDialog());
              }}
            />
          </div>
        ),
        accessor: (t: TeamMember) => t.team_role,
        Cell: ({ row: { original } }: CellProps<TeamMember>) => (
          <>
          {
              // allow if loggedinuser dcc otheruser any   
              // allow if loggedinuser superindendent otheruser any   
              // allow if loggedinuser principle otheruser any   
              // allow if loggedinuser teamchair otheruser any other than teamchampion     
              (((currentUserEditingAccessRoles.includes(original.user.profile?.role) && !readonly) || isDCC || isSuperintendent || isPrincipal
              || (teamRole === TeamRole.TeamChair)) && (original?.team_role !== TeamRole.TeamChampion) || (original?.team_role !== TeamRole.TeamChampion && original.user?.email === currentUser?.email)) ?
              <>
                 <input type="text" style={{display:"none"}} disabled={false} ></input>

                <TeamMemberRoleCell teamMember={original} />
                </>
                :
                <div className="flex  disabledTag">
                  <textarea style={{display:"none"}} disabled={true} ></textarea>

                  <Tag 
                  id="disabled"
                  interactive round minimal large>
                    {intl.formatMessage(
                      {
                        id: `app.team-plan.team.roles.${original.team_role}`,
                      },
                      { role: original.team_role }
                    )}
                  </Tag>
                </div>
      }
          </>
        ),
      },
      {
        id: "is_dcc",
        Header: () => (
          <p className="team-members-dcc-container">
            {intl.formatMessage({
              id: "app.titles.district-climate-coordinator",
            })}
          </p>
        ),
        disableSortBy: true,
        hidden: !isDistrictActivePlan,
        accessor: (t: TeamMember) => t?.user?.profile?.is_dcc,
        Cell: ({ row: { original } }: CellProps<TeamMember>) => {
          const disabled =
            original.user?.profile?.role === UserRole.Principal ||
            (isDCC
              ? original.user?.profile?.role === UserRole.Superintendent &&
              !isSuperintendent
              : !isSuperintendent && !isAdmin) || !isDistrictRole(original.user?.profile?.role);

          return (
            <Checkbox
              className="flex justify-center items-center mb-0"
              data-tour="team-member-table-isDCC-checkbox"
              checked={original.user?.profile?.is_dcc}
              disabled={disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(
                  updateTeamMember.request({
                    body: {
                      planId: original.plan,
                      user: {
                        id: original.user.id,
                        is_dcc: e.target?.checked,
                        custom_role_name:
                          original?.user?.profile?.custom_role_name,
                      },
                    },
                  })
                );
               !setupCompleted &&  localStorage.setItem("tourSteps","isDccCheckMark")
              }}
            />
          );
        },
      },

      {
        Header: intl.formatMessage({
          id: "app.titles.invite.action",
        }),
        sortType: (a: any, b: any) => {
          return (
            b.original.user.first_name > a.original.user.first_name)
        },
        disableSortBy: true,
        disableFilters: true,
        accessor: (t: TeamMember) => {
          const inviteDate = inviteDateCalculator(
            14,
            new Date(t?.invite_expires_date as string)
          );
          const inviteDateString =
            inviteDate.getMonth() +
            1 +
            "/" +
            inviteDate.getDate() +
            "/" +
            inviteDate.getFullYear().toString().substr(-2);
          const isInviteNotAccepted =
            (t.invited_status === InviteStatus.Sent && t.invite_id) ||
            t.invited_status === InviteStatus.Expired;

          return (
            <div>
                {(!isDistrictRole(t.user?.profile?.role)) && !isDistrictActivePlan && ((!isInviteNotAccepted && isDistrictActivePlan &&
                  isSuperintendent) ||
                  (!isDistrictActivePlan && isTeamRoleHigherOrEqualTeamChair) ||
                  (isDCC &&
                    t.user?.profile?.role !== UserRole.Superintendent)) && (teamRole === TeamRole.TeamChair ?  // if team chair
                      t.user?.profile?.role !== UserRole.Principal : true)
                      &&
                      ((isSuperintendent || isDCC || teamRole === TeamRole.TeamChair) ? t.user?.profile?.role !== UserRole.Principal:true)
                      && (
                    // <Button
                    //   id={"edit_user"}
                    //   icon="edit"
                    //   title={intl.formatMessage({ id: "app.titles.edit" })}
                    //   onClick={handleTeamMemberEditClick(t)}
                    // />
                    <Tooltip title="Edit User" arrow>
                    <button id={"edit_user"}
                    title={intl.formatMessage({ id: "app.titles.edit" })}
                    onClick={handleTeamMemberEditClick(t)}>  
                    <Icon iconSize={20} icon="edit" />
                  </button>
                  </Tooltip>
                  )}
                  
                {((isDistrictActivePlan && showRemoveFromTeamButton(t)) || 
                (!isDistrictActivePlan && ((isSuperintendent||(isDCC && t.user?.profile?.role !== UserRole.Superintendent))  && (isDistrictRole(t.user?.profile?.role) || t.user?.profile?.role === UserRole.Principal)))) && 
                <>
                <Tooltip title="Remove from Team" arrow>

                    
                    <button
                    className="removeUser"
                    id={"remove_user"}
                    title={intl.formatMessage({
                      id: "app.dialogs.user-editing-dialog.remove-from-team",
                    })}
                    onClick={() => handleDeleteOpenModal(t)}
                  >
                    <Icon iconSize={20} icon="remove" />
                  </button>
                  </Tooltip>
                  </>}
              {isInviteNotAccepted && (
                  <Tooltip title="Resend Invite" arrow>
                    <button className="resendInvite"
                    onClick={() => {
                      handleInviteMemberOpenModal(t);
                    }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                        <path d="M3.84846 22.5402C3.86662 22.7863 3.70894 23.011 3.47144 23.0777C3.23393 23.1445 2.9824 23.0344 2.87005 22.8147C1.99177 21.0961 1.23537 19.3178 0.6061 17.4931C-0.00319953 15.7265 -0.0321502 13.8112 0.523574 12.027C1.0793 10.2428 2.19043 8.68291 3.69489 7.57519C5.19923 6.4677 7.01815 5.87023 8.88577 5.87023H15.19V1.41357C15.19 1.20411 15.3168 1.01551 15.5106 0.936413C15.7044 0.857303 15.9268 0.903332 16.0734 1.05292L23.607 8.75177H23.6068C23.8029 8.95224 23.8029 9.27279 23.6068 9.47329L16.0732 17.1721H16.0734C15.9268 17.3217 15.7044 17.3678 15.5106 17.2886C15.3168 17.2095 15.19 17.0209 15.19 16.8115V12.355H9.84542V12.3548C8.20554 12.3548 6.63291 13.0066 5.47345 14.1668C4.314 15.3271 3.66258 16.9006 3.66258 18.5414C3.63472 19.8771 3.69692 21.213 3.84849 22.5404L3.84846 22.5402Z" fill="#155b98"/>
                      </svg>
                    </button>
                  </Tooltip>
              )}
              {!isInviteNotAccepted && !(((isDistrictActivePlan && showRemoveFromTeamButton(t)) || 
                (!isDistrictActivePlan && ((isSuperintendent||(isDCC && t.user?.profile?.role !== UserRole.Superintendent))  && (isDistrictRole(t.user?.profile?.role) || t.user?.profile?.role === UserRole.Principal))))) && !((!isDistrictRole(t.user?.profile?.role)) && !isDistrictActivePlan && ((!isInviteNotAccepted && isDistrictActivePlan &&
                  isSuperintendent) ||
                  (!isDistrictActivePlan && isTeamRoleHigherOrEqualTeamChair) ||
                  (isDCC &&
                    t.user?.profile?.role !== UserRole.Superintendent)) && (teamRole === TeamRole.TeamChair ?  // if team chair
                      t.user?.profile?.role !== UserRole.Principal : true)
                      &&
                      ((isSuperintendent || isDCC || teamRole === TeamRole.TeamChair) ? t.user?.profile?.role !== UserRole.Principal:true))
                      && ("NA")}
            </div>
          );
        },
      },
    ];
    return cols.filter((col) => !col.hidden);
  }, [
    currentUserEditingAccessRoles,
    isDistrictActivePlan,
    role,
    isSuperintendent,
    isTeamRoleHigherOrEqualTeamChair,
    readonly,
    isAdmin,
    disableDccSelector,
  ]);
  return (
    <><>
    {showManageUsersSection && (
      <div className="memberTbl_head" >
        <label>({members.length}) Team Members</label>
        <div style={{ position: "relative"}}>
        <div className="memberTbl_button">
          <button 
          data-tour="manage-users-tour" className="nj-btn"
          title={intl.formatMessage({id: inviteUserButtonTitle,})}
          onClick={handleShowInviteUsersDialog}
          >
            <Icon iconSize={16} icon="small-plus" />
            {intl.formatMessage({id: inviteUserButtonTitle,})}
          </button>
          <button 
          className="nj-btn"
          title={intl.formatMessage({
            id: "app.team-plan.setup.team.add-existing-user",
          })}
          onClick={handleShowAddUsersToPlanDialog}
          >
            <Icon iconSize={16} icon="small-plus" />
            {intl.formatMessage({id: "app.team-plan.setup.team.add-existing-user",})}
          </button>
        </div>
        </div>
       
      </div>
    )}
    
    </>
    <TeamEntityTable
        data={members}
        columns={columns}
        showTopContainer={false}
        darkHeader
        pageInd={pageInd}
        customPageIndex={pageInd.current}
        noDataComp={<div />}
        fixed={true} />
        
        
    {DeleteMemberModal}
    {resendInviteMemberModal}
        
        </>

    
  );
};

export default TeamMembersTable;
